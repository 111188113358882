import { FormsFieldPreset } from './field-types'
import { FormPlugin } from './plugins'

export type PremiumRestriction = {
  forms: { limit: number; threshold?: number }
  fields: { limit: number; threshold?: number }
  submissions: { limit: number; threshold?: number }
  steps: { limit: number; threshold?: number }
  allowedFields: { uploadButton?: boolean; generalUploadButton?: boolean }
  allowedRedirections: { downloadFile?: boolean }
  allowedPlugins: { [FormPlugin.PAYMENT_FORM]?: boolean }
  isTopPremium?: boolean
}

export type Feature = {
  id: string,
  uniqueName: string,
  quotaFeature?: {
      limit: number,
  },
}

export enum upgradeAlertType {
  FIELDS_LIMIT = 'fieldsLimit',
  STEPS_LIMIT = 'stepsLimit',
  STEPS_DUPLICATE_LIMIT = 'stepsDuplicateLimit',
  UPLOAD_BUTTON = 'uploadButton',
  DOWNLOAD_REDIRECT = 'downloadRedirect',
}

export const DEFAULT_RESTRICTIONS: PremiumRestriction = {
  forms: { limit: 5, threshold: 3 },
  fields: { limit: 10, threshold: 7 },
  submissions: { limit: 100, threshold: 70 },
  steps: { limit: 3, threshold: 2 },
  allowedRedirections: {},
  allowedFields: {},
  allowedPlugins: {},
}

export const getAscendPackagePickerUrl = (msid, appDefId, origin, vertical: string) =>
  `https://www.wix.com/apps/upgrade?metaSiteId=${msid}&appDefId=${appDefId}&pp_origin=${origin}_${vertical}`

export enum BillingPanelReferrer {
  GFPP = 'GPFF',
  MANAGE_FIELDS_LINK = 'manage_fields',
  ADD_FIELD_LINK = 'add_new_field',
  NUMBER_OF_FIELDS_ALERT = 'number_of_fields_alert',
  NUMBER_OF_STEPS_ALERT = 'number_of_steps_alert',
  UPLOAD_FIELD_ALERT = 'upload_field_alert',
  DOWNLOAD_REDIRECT_ALERT = 'download_redirect_alert',
  ADI_UPLOAD_FIELD = 'adi_upload_field',
  ADI_PREMIUM_ALERT_SECTION = 'adi_premium_alert_section',
}

export const referrerByAlertType = {
  [upgradeAlertType.FIELDS_LIMIT]: BillingPanelReferrer.NUMBER_OF_FIELDS_ALERT,
  [FormsFieldPreset.GENERAL_UPLOAD_BUTTON]: BillingPanelReferrer.UPLOAD_FIELD_ALERT,
}
